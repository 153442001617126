
import { defineComponent } from "vue";
import dateFormat from "../../utils/dateFormat";
export default defineComponent({
  name: "TopicItem",
  props: {
    topicData: Object,
  },
  setup() {
    type substr = string | undefined | null;
    const subString = function (str: substr) {
      if (str && str.length > 200) {
        return str.substr(0, 200) + "...";
      } else {
        return str;
      }
    };

    return {
      subString,
      dateFormat,
    };
  },
});
